<template>

    <StaffList
        :heading="`Staff Directory`"
        :staffList="$store.getters.staff.map(mapStaff)"
        :loading="$store.getters.loading"
        @selected="chosen"></StaffList>

</template>

<script>

    import StaffList from '../components/layouts/StaffList';
    import {getStaff, getSchoolStaff} from "../lib/api";
    import {getPrivateStaff} from "../lib/private-api";
    import {debugMsg} from '../lib/helpers';
	export default {
		name: "staff-result-page",
		components: {
            StaffList
		},

        data: () => ({
            staff: [],
            selected: {},
        }),

        watch: {
            selected: function(newVal, oldVal) {
                this.$store.commit('setLoading', true);

                if (newVal != oldVal) {
                    getSchoolStaff(newVal["Unit Code"])
                    .then((res) => {
                        this.$store.commit('setLoading', false);

                        // Check if API returned any results
                        if (res.data.data.length == 0) {
                            this.$store.commit("errorMsg", "No results were found");
                        } else {
                            // store result in vuex
                            this.$store.commit("updateTemp", this.$store.getters.staff);
                            this.$store.commit("updateStaff", res);
                            this.$store.commit("updateBack", true);
                            this.$router.push({path: `/schools/${newVal["Unit Code"]}/staff` })
                        }
                    })
                    .catch((err) => {
                        // display error message
                        this.$store.commit('setLoading', false);
                        this.errorLogin(err);
                    });
                }
            }
        },

        methods: {
            chosen: function(choice) {
                this.selected = choice;
            },


            mapStaff: function(staff) {
                if(staff["Unit Code"] != undefined) {
                    return {
                        "Unit Code": staff["Unit Code"],
                        Position: staff["Unit Name"],
                        Extension: staff["Contact Telephone Number"],
                        isUnit: true
                    }
                } else {
                    return staff;
                }
            },


            /**
            * Request a list of availible campuses from the Staff directory API
            */
            requestStaff: function(search, criteria) {

                this.$store.commit('setLoading', true);
                let vm = this;

                // if logged in hit private API
                if (this.$store.getters.isLoggedIn) {
                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateStaff(accessToken, search, criteria)
                                .then((res) => {
                                    vm.successSearch(res);
                                })
                                .catch((err) => {
                                    vm.errorSearch(err);
                                });
                            },
                        (error) => {
                            this.$store.commit("errorMsg", `There was an error retrieving results`);
                        });
                } else {
                    // if not logged in hit public API
                    getStaff(search, criteria)
                        .then((res) => {
                            vm.successSearch(res);
                        })
                        .catch((err) => {
                            vm.errorSearch(err);
                        })
                }
            },

            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);

                // Check if API returned any results
                if (res.data.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);
                    this.staff = this.$store.getters.staff.map(this.mapStaff);
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                this.$store.commit('setLoading', false);
                this.$store.commit("errorMsg", `There was an error retrieving results`);
                this.errorLogin(err);
            },
        },

        mounted: function() {

            if (this.$store.getters.staff.length == 0) {
                var criteria = this.$route.params["criteria"];
                var search = this.$route.params["search"];

                // request staff
                this.requestStaff(search, criteria);

            } else {
                this.staff = this.$store.getters.staff.map(this.mapStaff);
            }
        }
	}
</script>